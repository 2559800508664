document.addEventListener('DOMContentLoaded', function () {
  // Obtener todos los contenedores de tabs
  const tabContainers = document.querySelectorAll('.nav-tabs');

  // Iterar sobre cada contenedor de tabs
  tabContainers.forEach(function (container) {
    // Obtener los enlaces de navegación dentro de este contenedor
    const navLinks = container.querySelectorAll('.nav-link');

    // Iterar sobre cada enlace y agregar un evento de clic
    navLinks.forEach(function (link) {
      link.addEventListener('click', function (event) {
        // Evitar el comportamiento predeterminado del enlace
        event.preventDefault();

        // Eliminar la clase "active" de todos los enlaces dentro del mismo contenedor
        navLinks.forEach(function (navLink) {
          navLink.classList.remove('active');
        });

        // Agregar la clase "active" solo al enlace clicado
        this.classList.add('active');
      });
    });
  });
});

$(document).ready(function() {
  // Cuando se haga clic en el botón "Save changes" del modal
  $('#exampleModalCenter .modal-footer .primario').click(function() {
    // Cerrar el modal
    $('#exampleModalCenter').modal('hide');

    // Obtener el botón dentro del div con la clase "acciones-tarea" y la clase "enlace"
    var $boton = $('.modal-prueba button.gris');

    // Eliminar la clase "gris" del botón
    $boton.removeClass('gris');

    // Obtener la imagen dentro del botón
    var $imagen = $boton.find('img');

    // Cambiar la ruta de la imagen
    $imagen.attr('src', 'img/iconos/checked-brand.svg');
  });
});


const checkboxes = document.querySelectorAll('.row-checkbox');
const accionesTabla = document.querySelector('.accionesTabla');

checkboxes.forEach(checkbox => {
  checkbox.addEventListener('change', function() {
    const row = this.closest('tr');
    if (this.checked) {
      row.classList.add('selected');
      accionesTabla.classList.add('visible'); // Agrega una clase para cambiar la opacidad
    } else {
      row.classList.remove('selected');
      accionesTabla.classList.remove('visible'); // Elimina la clase para ocultar la opacidad
    }
  });
});


var enlace = document.getElementById('desplegable1');


function desplegaFlecha(targetId) {
  var svg = document.getElementById(targetId);
  if (svg) {
    if (svg.classList.contains('rotable')) {
      svg.classList.remove('rotable');
      svg.classList.add('rotable2');
    } else if (svg.classList.contains('rotable2')) {
      svg.classList.remove('rotable2');
      svg.classList.add('rotable');
    }
  }
}

function navbarDesplegable() {
  var navbarTextos = document.querySelectorAll('.nav-link');
  var navbarTextos = document.querySelectorAll('.navbar');
  var navBarFunction = document.getElementById('navbarBrand');
  var navBarArrow = document.getElementById('nav-arrow');
  var navBarShadow = document.getElementById('background');


  navbarTextos.forEach(function(navbarTexto) {
    navbarTexto.classList.toggle("desplegado");
  });
  navbarBrand.classList.toggle("sm");
  navBarFunction.classList.toggle("desplegado");
  navBarArrow.classList.toggle("rotable");
  navBarShadow.classList.toggle("desplegado");

}

// Espera a que la página esté completamente cargada
document.addEventListener('DOMContentLoaded', function() {
  // Obtiene el modal por su ID
  // var modal = document.getElementById('mymodal');
  // Muestra el modal
  // var miModal = new bootstrap.Modal(modal);
  // miModal.show();
});


$('#miModalDesplegado').modal('show')


function poraseguradora(){
  var porAseguradora = document.getElementById('por-aseguradora');
  var porCobertura = document.getElementById('por-cobertura');
  porAseguradora.classList.remove("d-none");
  porAseguradora.classList.add("d-flex");
  porCobertura.classList.remove("d-flex");

  porCobertura.classList.add("d-none");


}
function porcobertura(){
  var porAseguradora = document.getElementById('por-aseguradora');
  var porCobertura = document.getElementById('por-cobertura');

  porAseguradora.classList.remove("d-flex");
  porAseguradora.classList.add("d-none");
  porCobertura.classList.remove("d-none");

  porCobertura.classList.add("d-flex");

}
function showCobertura(){
  var showCobertura = document.getElementById('showCobertura123');

  showCobertura.classList.toggle("d-none");


}

function showAseguradora(){
  var showAseguradora = document.getElementById('showAseguradora123');

  showAseguradora.classList.toggle("d-none");


}

function showProducto(){
  var showProducto = document.getElementById('showProducto');

  showProducto.classList.toggle("d-none");


}
function showProducto2(){
  var showProducto2 = document.getElementById('showProducto2');

  showProducto2.classList.toggle("d-none");


}

function cambiarDeshabilitado(){
  var btnDeshabilitado = document.getElementById('btn-deshabilitado');

  btnDeshabilitado.classList.remove("deshabilitado");
  btnDeshabilitado.classList.add("primario");

}

// Primer tab
document.querySelectorAll('.tab1 .nav-link2').forEach(item => {
  item.addEventListener('click', event => {
    document.querySelectorAll('.tab1 .nav-link2').forEach(item => {
      item.classList.remove('active');
    });
    event.currentTarget.classList.add('active');
  });
});

// Segundo tab
document.querySelectorAll('.tab2 .nav-link2').forEach(item => {
  item.addEventListener('click', event => {
    document.querySelectorAll('.tab2 .nav-link2').forEach(item => {
      item.classList.remove('active');
    });
    event.currentTarget.classList.add('active');
  });
});

function switchConfiguracion(targetId){
  var switchConfiguracion = document.getElementById(targetId);

  if (switchConfiguracion) {
    if (switchConfiguracion.classList.contains('configuracion-switch')) {
      switchConfiguracion.classList.remove('configuracion-switch');
      switchConfiguracion.classList.add('configuracion-switch-white');
    } else if (switchConfiguracion.classList.contains('configuracion-switch-white')) {
      switchConfiguracion.classList.remove('configuracion-switch-white');
      switchConfiguracion.classList.add('configuracion-switch');
    }
  }

};


function focusInput(){
  var inputLabel = document.getElementById('input-label');
  var inputText = document.getElementById('input-text');
  if (!inputLabel.classList.contains('active')){
    inputLabel.classList.add('active')
  }
  if (!inputText.classList.contains('active')){
    inputText.classList.add('active')
  }


}


// Función para agregar la clase 'visible' al elemento con la clase 'modal-shadow-evento'
function modalEvento() {
  var modalShadowElement = document.querySelector('.modal-shadow-evento');
  var shadowGeneral = document.querySelector('.modal-backdrop');


  modalShadowElement.classList.add('visible');
  shadowGeneral.classList.add('no-visible');


}
function modalEventoVehiculos() {
  var modalShadowElement = document.querySelector('.modal-shadow-evento2');
  var shadowGeneral = document.querySelector('.modal-backdrop');


  modalShadowElement.classList.add('visible');
  shadowGeneral.classList.add('no-visible');


}

// Función para quitar la clase 'visible' al elemento con la clase 'modal-shadow-evento'
function quitarModalEvento() {
  var modalShadowElement = document.querySelector('.modal-shadow-evento');

  modalShadowElement.classList.remove('visible');

}
function quitarModalEvento2() {
  var modalShadowElement = document.querySelector('.modal-shadow-evento2');
  modalShadowElement.classList.remove('visible');
}

function nuevaSolicitudCheck(radioButton) {
  // Obtén todos los radio buttons con el mismo nombre
  var radioButtons = document.getElementsByName('nuevaSolicitudCheck');
  var btnContinuar = document.getElementById('btnContinuar');

  // Elimina la clase 'active' de todos los labels
  for (var i = 0; i < radioButtons.length; i++) {
    var label = document.querySelector('label[for="' + radioButtons[i].id + '"]');
    label.classList.remove('active');
  }

  btnContinuar.classList.remove('deshabilitado');
  btnContinuar.classList.add('primario');
  // Agrega la clase 'active' solo al label del radio button activo
  var labelForActive = document.querySelector('label[for="' + radioButton.id + '"]');
  labelForActive.classList.add('active');
}

function nuevaSolicitudTargetBeneficios(){

  var btnContinuar = document.getElementById('btnContinuar');


  btnContinuar.removeAttribute('data-bs-target');
  btnContinuar.setAttribute('data-bs-target', '#nuevaSolicitudBeneficios');

}
function nuevaSolicitudTargetAutos(){

  var btnContinuar = document.getElementById('btnContinuar');


  btnContinuar.removeAttribute('data-bs-target');
  btnContinuar.setAttribute('data-bs-target', '#nuevaSolicitudAutos');

}
function nuevaSolicitudTargetDanos(){

  var btnContinuar = document.getElementById('btnContinuar');


  btnContinuar.removeAttribute('data-bs-target');
  btnContinuar.setAttribute('data-bs-target', '#nuevaSolicitudDanos');

}

// FUNCION PARA LOS ESTILOS DE RADIO BUTTONS
function radioButton(radio) {
  // Obtén todos los radio buttons del mismo grupo
  var radios = document.getElementsByName(radio.name);

  // Recorre todos los radio buttons del mismo grupo
  for (var i = 0; i < radios.length; i++) {
    var label = radios[i].parentNode;

    // Si el radio button actual está seleccionado, agrega la clase 'active' al label, de lo contrario, quítala
    if (radios[i].checked) {
      label.classList.add('active');
    } else {
      label.classList.remove('active');
    }
  }
}

// FUNCINONES PARA MOSTRAR OCULTAR EN FORMULARIOS

function toggleDisplay(elementToShow, elementToHide) {
  elementToShow.classList.remove("d-none");
  elementToShow.classList.add("d-flex");
  elementToHide.classList.remove("d-flex");
  elementToHide.classList.add("d-none");
}
function seleccionaAlta() {
  var porTitular = document.getElementById('seleccionasteAlta');
  var porBeneficiario = document.getElementById('seleccionasteBaja');
  toggleDisplay(porTitular, porBeneficiario);
}

function seleccionaBaja() {
  var porTitular = document.getElementById('seleccionasteAlta');
  var porBeneficiario = document.getElementById('seleccionasteBaja');
  toggleDisplay(porBeneficiario, porTitular);
}

function porTitular() {
  var porTitular = document.getElementById('porTitular');
  var porBeneficiario = document.getElementById('porBeneficiario');
  toggleDisplay(porTitular, porBeneficiario);
}
function porTitularBaja() {
  var porTitularBaja = document.getElementById('porTitularBaja');
  var porBeneficiarioBaja = document.getElementById('porBeneficiarioBaja');
  toggleDisplay(porTitularBaja, porBeneficiarioBaja);
}


function porBeneficiario() {
  var porTitular = document.getElementById('porTitular');
  var porBeneficiario = document.getElementById('porBeneficiario');
  toggleDisplay(porBeneficiario, porTitular);
}
function porBeneficiarioBaja() {
  var porTitularBaja = document.getElementById('porTitularBaja');
  var porBeneficiarioBaja = document.getElementById('porBeneficiarioBaja');
  toggleDisplay(porBeneficiarioBaja, porTitularBaja);
}

// DESPLEGAR EL PRIMER FORM DE BENEFICIARIO CON LOS RADIO BUTTONS

function showFormBeneficiarioRadioButton() {
  var formBeneficiario = document.getElementById('formBeneficiarioRadio');
  formBeneficiario.classList.remove("d-none");
  formBeneficiario.classList.add("d-flex");
}
function hiddeFormBeneficiarioRadioButton() {
  var formBeneficiario = document.getElementById('formBeneficiarioRadio');
  formBeneficiario.classList.remove("d-flex");
  formBeneficiario.classList.add("d-none");
}

// PARA INSERTAR UN NUEVO BENEFICIARIO
function showFormBeneficiario() {
  // Crear un div para contener el formulario del beneficiario
  var formContainer = document.createElement('div');
  formContainer.classList.add('form-beneficiario', 'mt-4');

  // HTML del formulario del beneficiario
  var formHtml = `
      <div class="form-row">
      <div class="form-group">
        <label for="nombreBeneficiario">Nombre del beneficiario</label>
        <input type="text" class="form-control ammiaAdmin-style" id="nombreBeneficiario" name="nombreBeneficiario">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group">
        <label for="apellidoPaterno">Apellido paterno</label>
        <input type="text" class="form-control ammiaAdmin-style" id="apellidoPaterno" name="apellidoPaterno" >
      </div>
      <div class="form-group">
        <label for="apellidoMaterno">Apellido materno</label>
        <input type="text" class="form-control ammiaAdmin-style" id="apellidoMaterno" name="apellidoMaterno">
      </div>

    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
          <label for="fecha">Fecha de nacimiento</label>
          <input type="date" class="form-control ammiaAdmin-style" id="fechaNacimiento" name="fechaNacimiento" placeholder="Selecciona una fecha">
      </div>
      <div class="form-group col-md-6">
        <label for="fecha">Fecha de alta</label>
        <input type="date" class="form-control ammiaAdmin-style" id="fechaAlta" name="fechaAlta" placeholder="Selecciona una fecha">
      </div>

    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="tipoTarea">Parentesco con asegurado titular</label>
        <select class="form-control ammiaAdmin-style" id="tipoTarea" name="tipoTarea" required onchange="showCobertura()" >
            <option value="" disabled selected hidden> Selecciona</option>
            <option value="opcion1" >Cónyuge</option>
            <option value="opcion2">Hijo</option>
            <option value="opcion3">Opcion 3</option>
            <option value="opcion4">Opcion 4</option>
            <!-- Agrega más opciones aquí -->
        </select>
      </div>
    </div>
  `;

  // Insertar el HTML del formulario en el contenedor
  formContainer.innerHTML = formHtml;
// Obtener el contenedor donde se insertará el formulario
  var container = document.getElementById('formBeneficiarioContenedor');

// Insertar el contenedor del formulario al final del contenedor principal
  container.appendChild(formContainer);
}


// PARA INSERTAR UN NUEVO BENEFICIARIO
function showFormAdaptacion() {
  // Crear un div para contener el formulario del beneficiario
  var formContainer = document.createElement('div');
  formContainer.classList.add('form-beneficiario', 'mt-4');

  // HTML del formulario del beneficiario
  var formHtml = `
  <!-- Nombre -->
  <div class="form-row">
    <div class="form-group">
      <label for="nombre">Nombre de la adaptación</label>
      <input type="text" class="form-control ammiaAdmin-style" id="nombre" name="nombre" >
    </div>
  </div>

  <div class="form-row">
    <div class="form-group">
      <label for="adaptacion">Valor de la adaptación</label>
      <input type="text" class="form-control ammiaAdmin-style" id="adaptacion" name="adaptacion"  placeholder="$" >
    </div>
  </div>
  `;

  // Insertar el HTML del formulario en el contenedor
  formContainer.innerHTML = formHtml;
// Obtener el contenedor donde se insertará el formulario
  var container = document.getElementById('formAdaptacionContenedor');

// Insertar el contenedor del formulario al final del contenedor principal
  container.appendChild(formContainer);
}






function showFormBeneficiario2() {
  var formBeneficiario2 = document.getElementById('formBeneficiario2');


  formBeneficiario2.classList.remove("d-none");
  formBeneficiario2.classList.add("d-flex");


}

function showFormBeneficiario3() {
  var formBeneficiario3 = document.getElementById('formBeneficiario3');


  formBeneficiario3.classList.remove("d-none");
  formBeneficiario3.classList.add("d-flex");


}



// PARA ACTIVAR EL BOTON DE GENERAR SOLICITUD VEHICULO
function activeButtonGenerarVehiculo(){
  var btnGenerarSolicitud = document.getElementById('btnGenerarSolicitudVehiculo');
  btnGenerarSolicitud.classList.remove("deshabilitado");
  btnGenerarSolicitud.classList.add("primario");
  btnGenerarSolicitud.setAttribute("data-bs-dismiss", "offcanvas");
  btnGenerarSolicitud.setAttribute("aria-label", "Close");
}


// PARA ACTIVAR EL BOTON DE GENERAR SOLICITUD
function activeButtonGenerar(){
  var btnGenerarSolicitud = document.getElementById('btnGenerarSolicitud');
  btnGenerarSolicitud.classList.remove("deshabilitado");
  btnGenerarSolicitud.classList.add("primario");
  btnGenerarSolicitud.setAttribute("data-bs-dismiss", "offcanvas");
  btnGenerarSolicitud.setAttribute("aria-label", "Close");
}
// PARA ACTIVAR EL BOTON DE GENERAR SOLICITUD
function activeButtonGenerarAltaMultiple(){

  var btnGenerarSolicitud = document.getElementById('btnGenerarSolicitud');
  btnGenerarSolicitud.classList.remove("deshabilitado");
  btnGenerarSolicitud.classList.add("primario");
  btnGenerarSolicitud.setAttribute('data-bs-target', '#altaMultipleValidacion');
}



function cargarReconocimiento() {
  var reconocimientoAntiguedad = document.getElementById('reconocimientoAntiguedad');

  var reconocimientoAntiguedadCargado = document.getElementById('reconocimientoAntiguedadCargado');

  reconocimientoAntiguedadCargado.classList.remove("d-none");
  reconocimientoAntiguedadCargado.classList.add("d-flex");

  reconocimientoAntiguedad.classList.remove("d-flex");
  reconocimientoAntiguedad.classList.add("d-none");

}



function cargarReconocimiento2() {
  var reconocimientoAntiguedad2 = document.getElementById('reconocimientoAntiguedad2');

  var reconocimientoAntiguedadCargado2 = document.getElementById('reconocimientoAntiguedadCargado2');

  reconocimientoAntiguedadCargado2.classList.remove("d-none");
  reconocimientoAntiguedadCargado2.classList.add("d-flex");

  reconocimientoAntiguedad2.classList.remove("d-flex");
  reconocimientoAntiguedad2.classList.add("d-none");

}
function cargarFacturaVehiculo() {
  var facturaVehiculo = document.getElementById('facturaVehiculo');

  var facturaVehiculoCargado = document.getElementById('facturaVehiculoCargado');
  facturaVehiculoCargado.classList.remove("d-none");
  facturaVehiculoCargado.classList.add("d-flex");

  facturaVehiculo.classList.remove("d-flex");
  facturaVehiculo.classList.add("d-none");

}


function cargarPlantillaAlta() {
  var subirPlantillaAlta = document.getElementById('subirPlantillaAlta');
  var editarPlantillaAlta = document.getElementById('editarPlantillaAlta');

  editarPlantillaAlta.classList.remove("d-none");
  editarPlantillaAlta.classList.add("d-flex");

  subirPlantillaAlta.remove("d-flex");
  subirPlantillaAlta.add("d-none");


}


// POLIZA MAESTRA
// Función genérica para seleccionar la poliza maestra
function seleccionaPolizaMaestraGeneric(radioButton, radioGroupName) {
  // Obtén todos los radio buttons con el mismo nombre
  var radioButtons = document.getElementsByName(radioGroupName);

  // Elimina la clase 'active' de todos los labels
  for (var i = 0; i < radioButtons.length; i++) {
    var label = document.querySelector('label[for="' + radioButtons[i].id + '"]');
    label.classList.remove('active');
  }

  // Agrega la clase 'active' solo al label del radio button activo
  var labelForActive = document.querySelector('label[for="' + radioButton.id + '"]');
  labelForActive.classList.add('active');
}






// Función genérica para desmarcar las opciones de los radios-hijos según la opción seleccionada en los radios-padres
function desmarcarRadiosHijosGeneric(radiosHijosName, radiosPadresName) {
  const radiosHijos = document.getElementsByName(radiosHijosName);
  // Desmarcar todas las opciones de los radios-hijos
  radiosHijos.forEach(function (radioHijo) {
    radioHijo.checked = false;
  });

  // Buscar los divs con la clase .form-check dentro de la opción NO seleccionada de los radios-padres
  const radiosPadres = document.getElementsByName(radiosPadresName);
  radiosPadres.forEach(function (radioPadre) {
    if (!radioPadre.checked) {
      const divOpciones = radioPadre.nextElementSibling.querySelector('div');
      divOpciones.querySelectorAll('.form-check').forEach(function (div) {
        div.classList.remove('active');
      });
    }
  });
}

function desmarcarRadiosHijosGeneric2(radiosHijosName, radiosPadresName) {
  const radiosHijos = document.getElementsByName(radiosHijosName);
  // Desmarcar todas las opciones de los radios-hijos
  radiosHijos.forEach(function (radioHijo) {
    radioHijo.checked = false;
  });

  // Buscar los divs con la clase .form-check dentro de la opción NO seleccionada de los radios-padres
  const radiosPadres = document.getElementsByName(radiosPadresName);
  radiosPadres.forEach(function (radioPadre) {
    if (!radioPadre.checked) {
      const divOpciones = radioPadre.nextElementSibling.querySelector('div');
      divOpciones.querySelectorAll('.form-check').forEach(function (div) {
        div.classList.remove('active');
      });
    }
  });
}

function nuevaSolicitudCheck2(radioButton) {
  console.log('nuevasolicitudcheck2');
  // Obtén todos los radio buttons con el mismo nombre
  var radioButtons = document.getElementsByName('polizaMaestraSolicitud');


  // Elimina la clase 'active' de todos los labels
  for (var i = 0; i < radioButtons.length; i++) {
    var label = document.querySelector('label[for="' + radioButtons[i].id + '"]');
    label.classList.remove('active');
  }

  // Agrega la clase 'active' solo al label del radio button activo
  var labelForActive = document.querySelector('label[for="' + radioButton.id + '"]');
  labelForActive.classList.add('active');
}








function showCobertura(){
  var showCobertura = document.getElementById('showCobertura123');

  showCobertura.classList.toggle("d-none");


}

function showAseguradora(){
  var showAseguradora = document.getElementById('showAseguradora123');

  showAseguradora.classList.toggle("d-none");


}

function showProducto(){
  var showProducto = document.getElementById('showProducto');

  showProducto.classList.toggle("d-none");


}
function showProducto2(){
  var showProducto2 = document.getElementById('showProducto2');

  showProducto2.classList.toggle("d-none");


}

function cerrarReportarSinietro(){
  var modalReportarSiniestro = document.getElementById('reportarSiniestro');

  modalReportarSiniestro.classList.remove("show");
}

function displayVentanaAvatar(){
  var ventanaAvatar = document.getElementById('ventanaAvatar');

  var ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');
  ventanaAvatar.classList.toggle("active");
  ventanaCambioContrasena.classList.remove("active");
}
function cambiarContrasena(){
  var ventanaAvatar = document.getElementById('ventanaAvatar');
  var ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');

  ventanaAvatar.classList.remove("active");
  ventanaCambioContrasena.classList.add("active");
}
function cambiarContrasenaFinalizar(){
  var ventanaAvatar = document.getElementById('ventanaAvatar');
  var ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');
  var ventanaCambioContrasenaFinalizar = document.getElementById('ventanaCambioContrasenaFinalizar');

  ventanaCambioContrasena.classList.remove("active");
  ventanaCambioContrasenaFinalizar.classList.add("active");
}
function regresarVentanaAvatar(){
  var ventanaAvatar = document.getElementById('ventanaAvatar');
  var ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');

  ventanaAvatar.classList.add("active");
  ventanaCambioContrasena.classList.remove("active");
}
function cerrarVentanaAvatar(){
  var ventanaAvatar = document.getElementById('ventanaAvatar');
  var ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');
  var ventanaCambioContrasenaFinalizar = document.getElementById('ventanaCambioContrasenaFinalizar');

  ventanaAvatar.classList.remove("active");
  ventanaCambioContrasena.classList.remove("active");
  ventanaCambioContrasenaFinalizar.classList.remove("active");
}

function cerrarModalAbrirOffcanvas() {
  var myModal = new bootstrap.Modal(document.getElementById('altaMultipleValidacionModificarDatos'));
  var myOffcanvas = new bootstrap.Offcanvas(document.getElementById('altaMultipleValidacion'));
  myModal.hide();
  myOffcanvas.show();
};
function abrirconfirmación() {
  var myModal = new bootstrap.Modal(document.getElementById('nuevaSolicitud'));
  var myOffcanvas = new bootstrap.Offcanvas(document.getElementById('altaMultipleValidacionModificarDatos'));
  myModal.hide();
  myOffcanvas.show();
}

function pruebaCheckbox(checkBoxPrueba) {
  var checkboxId = checkBoxPrueba.id;
  var label = document.querySelector("label[for='" + checkboxId + "']");

  if (!label) return;

  if (checkBoxPrueba.checked) {
    label.classList.add('active');
  } else {
    label.classList.remove('active');

    // Deseleccionar todos los inputs de tipo radio dentro del label asociado
    var radioButtons = label.querySelectorAll("input[type='radio']");
    radioButtons.forEach(radioButton => {
      radioButton.checked = false;
    });

    // Remover clase 'active' de elementos con clase 'radio-button-check-prueba'
    var radioButtonContainers = label.querySelectorAll(".radio-button-check-prueba");
    radioButtonContainers.forEach(container => {
      container.classList.remove('active');
    });
  }

  //console.log(checkboxId);
  //console.log(label);
}
